import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { PageSEO } from "../components/seo/page-seo";
import { BiographyNavigation } from "../components/biography/biography-navigation";

const Page = styled.div`
  margin: 0 0 130px 0;

  & .body-text {
    & p {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.05em;

      margin: 0;
      text-indent: 99px;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;

        text-indent: 35px;
      }
    }

    &.first {
      & p:first-of-type {
        text-indent: unset;
      }
    }
  }

  & .credit-text {
    margin: 27px 0 0 0;

    & > div {
      max-width: 615px;

      & p {
        font-size: 11px;
        line-height: 17px;
        letter-spacing: 0.05em;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 55px 0;
  }
`;

const Grid = styled.div`
  & .body-text {
    max-width: 820px;

    & p {
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & .image {
    max-width: 820px;
    margin: 30px 0;
    padding: 0 60px;

    & img {
      max-width: 620px;
      margin: 0 auto;
    }

    @media (max-width: 768px) {
      margin: 25px 0;
      padding: 0 35px;
    }
  }
`;

const Biography = ({ data }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  useEffect(() => {
    setPageBackgroundColor({
      text: `#163262`,
      background: `#FF8513`,
    });
  }, []);

  const content = data.prismicBiography.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <div
          className={`body-text ${index === 0 ? `first` : ``}`}
          key={`single_body_text_${index}_${content.id}`}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === "credit") {
      return (
        <div
          className="credit-text"
          key={`single_credit_text_${index}_${content.id}`}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === "image") {
      return (
        <div className="image" key={`single_image_${index}_${content.id}`}>
          {content.primary.image.fluid !== null && (
            <img
              srcSet={content.primary.image.fluid.srcSetWebp}
              src={content.primary.image.fluid.srcWebp}
              alt={content.primary.image.alt}
              loading={`lazy`}
            />
          )}
        </div>
      );
    }
  });

  return (
    <>
      <PageSEO
        title={data.prismicBiography.data.title.text}
        description={null}
        image={null}
        url={`https://yinkashonibare.com${data.prismicBiography.url}`}
      />

      <Page>
        <BiographyNavigation bibliographyTags={false} cvTags={false} />
        <Grid>{content}</Grid>
      </Page>
    </>
  );
};

export default withPreview(Biography);

export const query = graphql`
  {
    prismicBiography {
      url
      data {
        title {
          text
        }
        body {
          ... on PrismicBiographyBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBiographyBodyCredit {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBiographyBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
