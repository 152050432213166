import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: 28px 0 20px 0;

  @media (max-width: 1075px) {
    margin: 3px 0 20px 0;
  }

  @media (max-width: 768px) {
    margin: 0 0 20px 0;
  }

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & li {
      margin: 0 15px 0 0;

      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.05em;

      & a {
        &:hover,
        &.current {
          color: #4383ef;
        }
      }
    }

    & button {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.05em;

      text-transform: capitalize;
      text-align: left;

      margin: 0 15px 0 0;

      &.view-all {
        margin: 0 25px 0 40px;
        text-transform: none;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

  & .section-navigation {
    margin: 0 40px 0 0;
  }

  & .bibliography-tags,
  & .cv-tags {
    & li {
      & button {
        & span {
          opacity: 0;
          transition: 250ms opacity ease;
        }

        &:hover,
        &.active-tag {
          & span {
            opacity: 1;
          }
        }
      }
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

  & .cv-tags {
    @media (max-width: 1200px) {
      display: none;
    }
  }
`;

export const BiographyNavigation = ({
  bibliographyTags,
  activeTag,
  setActiveTag,
  cvTags,
  activeCvTag,
  setActiveCvTag,
}) => {
  const data = useStaticQuery(graphql`
    {
      prismicBiography {
        url
        data {
          title {
            text
          }
        }
      }
      prismicBibliography {
        url
        data {
          title {
            text
          }
        }
      }
      prismicCv {
        url
        data {
          title {
            text
          }
        }
      }
    }
  `);

  return (
    <Navigation>
      <ol className="section-navigation">
        <li>
          <Link to={data.prismicBiography.url} activeClassName="current">
            {data.prismicBiography.data.title.text}
          </Link>
        </li>

        <li>
          <Link to={data.prismicCv.url} activeClassName="current">
            {data.prismicCv.data.title.text}
          </Link>
        </li>

        <li>
          <Link to={data.prismicBibliography.url} activeClassName="current">
            {data.prismicBibliography.data.title.text}
          </Link>
        </li>
      </ol>

      {cvTags !== false && (
        <ol className="cv-tags">
          <li>
            <button
              onClick={() => setActiveCvTag(`all`)}
              className={
                activeCvTag === `all` ? `view-all active-tag` : `view-all`
              }
            >
              <span>(</span>View All<span>)</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveCvTag(`memberships`)}
              className={activeCvTag === `memberships` ? `active-tag` : ``}
            >
              <span>(</span>
              Memberships
              <span>)</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveCvTag(`exhibitions`)}
              className={activeCvTag === `exhibitions` ? `active-tag` : ``}
            >
              <span>(</span>
              Exhibitions
              <span>)</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveCvTag(`group-exhibitions`)}
              className={
                activeCvTag === `group-exhibitions` ? `active-tag` : ``
              }
            >
              <span>(</span>
              Group Exhibitions
              <span>)</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveCvTag(`projects`)}
              className={activeCvTag === `projects` ? `active-tag` : ``}
            >
              <span>(</span>
              Projects
              <span>)</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveCvTag(`public-art`)}
              className={activeCvTag === `public-art` ? `active-tag` : ``}
            >
              <span>(</span>
              Public Art
              <span>)</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveCvTag(`awards`)}
              className={activeCvTag === `awards` ? `active-tag` : ``}
            >
              <span>(</span>
              Awards
              <span>)</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveCvTag(`collections`)}
              className={activeCvTag === `collections` ? `active-tag` : ``}
            >
              <span>(</span>
              Collections
              <span>)</span>
            </button>
          </li>
        </ol>
      )}

      {bibliographyTags !== false && (
        <ol className="bibliography-tags">
          <li>
            <button
              onClick={() => setActiveTag(`all`)}
              className={
                activeTag === `all` ? `view-all active-tag` : `view-all`
              }
            >
              <span>(</span>View All<span>)</span>
            </button>
          </li>

          <li>
            <button
              onClick={() => setActiveTag(`catalogues`)}
              className={activeTag === `catalogues` ? `active-tag` : ``}
            >
              <span>(</span>
              Exhibition Catalogues & Anthologies
              <span>)</span>
            </button>
          </li>

          <li>
            <button
              onClick={() => setActiveTag(`periodicals`)}
              className={activeTag === `periodicals` ? `active-tag` : ``}
            >
              <span>(</span>
              Periodicals
              <span>)</span>
            </button>
          </li>
        </ol>
      )}
    </Navigation>
  );
};
